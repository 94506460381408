import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthPreloadStrategy } from './core/auth/services/auth-preload-strategy.service';
import { AuthGuard } from './core/security/guards/auth.guard';
import { OfflineGuard } from './core/security/guards/offline.guard';
import {
  ExternalURLsComponent,
  RedirectSiteGuard
} from './core/security/guards/redirect-site.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/auth/auth.routes').then((m) => m.AuthRoutes)
  },
  {
    path: 'assistir-video/:id',
    loadChildren: () =>
      import('./modules/watch-external-video/watch-external-video.module').then(
        (m) => m.WatchExternalVideoModule
      )
  },
  {
    path: 'plano-de-aula/:external_id',
    loadChildren: () =>
      import('./modules/lesson-plan/lesson-plan.module').then(
        (m) => m.LessonPlanModule
      ),
    canLoad: [AuthGuard],
    canActivate: [OfflineGuard]
  },
  {
    path: 'link-expirado',
    loadChildren: () =>
      import('./modules/expired-link/expired-link.module').then(
        (m) => m.ExpiredLinkModule
      ),
    canLoad: [AuthGuard],
    canActivate: [OfflineGuard]
  },
  {
    path: 'offline',
    loadChildren: () =>
      import('./modules/offline/offline.module').then((m) => m.OfflineModule)
  },
  {
    path: 'users-management',
    loadChildren: () =>
      import('./modules/users-management/users-management.module').then(
        (m) => m.UsersManagementModule
      ),
    canLoad: [AuthGuard]
  },
  {
    path: 'ciclos',
    loadChildren: () =>
      import('./features/cycles/routes/cycles.routes').then((r) => r.routes),
    canLoad: [AuthGuard]
  },
  {
    path: 'material',
    canActivate: [RedirectSiteGuard],
    component: ExternalURLsComponent
  },
  {
    path: '',
    loadChildren: () =>
      import('./shell/shell.module').then((m) => m.ShellModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: AuthPreloadStrategy,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
